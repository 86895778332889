@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/amazon-ember');
                

body {
  background-color: #fcfcfc;
  font-family: 'Amazon Ember V2';
}

*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: rgb(0 0 0 / 0.1);
}

input, textarea {
color: #000;
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 40px;
  line-height: 1;
  opacity: .75;
  color: #2E3192 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next  {
  right: -10px !important;
  z-index: 30 !important;
}

.slick-prev {
  left: -10px !important;
  z-index: 30 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e7e6e6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2E3192;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #282b79;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Set a high z-index to ensure it appears above other elements */
}

.loader-content {
  background: #fff; /* White background for the loader content */
  padding: 20px;
  border-radius: 8px;
}

.paginationBttns {
  width: 100%;
  height: 25px;
  list-style: none;
  display: flex;
  justify-content: flex-end;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #282b79;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: white;
  background-color: #282b79;
}

.paginationActive a{
  color: white;
  background-color: #282b79;
}

.paginateDisabled a{
  color: white;
  background-color: gray;
}

.react-calendar {
  width: 100% !important;
  max-width: 100%;
  background: white;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5em;
}

.react-calendar__tile--active {
  background: #282b79 !important;
  color: white;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
  background-color: #282b79 !important;
  color: white;
}
